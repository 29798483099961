import React, { useRef, useState } from 'react'
import './assets/scss/SearchBar.scss'

export default function SearchBar({ updateResults }) {
  const [results, setResults] = useState([]);
  const query = useRef();
  let debounceTimeout;

  const handleInputChange = () => {
    const queryval = query.current.value.trim();
    // Effacez tout délai précédent de déclenchement de la recherche
    clearTimeout(debounceTimeout);

    // Déclenchez la recherche après un délai de 300 ms (ou autre valeur de votre choix)
    debounceTimeout = setTimeout(() => {
      const searchQuery = queryval ? `s=${queryval}%` : ''; // Construct the search query string
      const url = `https://phasmophobiaastuce.fr/API_phasmophobiaastuce/ghost?${searchQuery}`; // Build the complete URL
      fetch(url, {mode:'cors'})
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // console.log(data, 'c\'est de ma merde');
          updateResults(data, queryval);
          // setResults(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }, 300);
  }
  
  return (
    <form onSubmit={handleInputChange}>
      <ul className='search-ul'>
        <input autoFocus={true} ref={query} onChange={handleInputChange} className='search-input' type="text" placeholder=' Search...' />
        {/* <button type="submit">Search</button> */}
      </ul>
      <ul>
        {results.map((result, index) => {
          console.log(result);
          return(
            <li key={index}>{result.name}</li>
          )
        })}
      </ul>
    </form>
  )
}