import React, { useContext, useState } from 'react';
import { ImageContext } from '../ImageProvider';

import './Random.scss'


export default function Aleatoire() {
  const images = useContext(ImageContext);

  const objets = [
	{nom : "EMF", image: images.emf2},
	{nom : "Spirit box", image: images.spiritbox2},
	{nom : "Livre", image: images.book2},
	{nom : "UV", image: images.uv2},
	{nom : "Caméra", image: images.camera2},
	{nom : "Capteur sonor", image: images.sonor2},
	{nom : "Capteur de mouvement", image: images.movement2},
	{nom : "D.O.T.S", image: images.dots2},
	{nom : "Pillules", image: images.pillule2},
	{nom : "Encens", image: images.encens2},
	{nom : "Bougie", image: images.candle2},
	{nom : "Parabole", image: images.parabol2},
	{nom : "Torche", image: images.torche2},
	{nom : "Sel", image: images.sel2},
	{nom : "Trépied", image: images.trepied2},
	{nom : "Thermomètre", image: images.thermo2},
	{nom : "Briquet", image: images.flint2},
	{nom : "Crucifix", image: images.crucifix2},
	{nom : "photo", image: images.photo2}
  ]

  const Tiers = [
	"Tier 1",
	"Tier 2",
	"Tier 3"
  ];

  const [objetsAleatoires, setObjetsAleatoires] = useState([]);

  function obtenirObjetsAleatoires() {
    const objetsUniques = new Set();

    while (objetsUniques.size < 3) {
      const randomObject = objets[Math.floor(Math.random() * objets.length)];
      objetsUniques.add(randomObject);
    }

    setObjetsAleatoires(Array.from(objetsUniques));
  }

  function getRandomTiers() {
    const randomTiers = Tiers[Math.floor(Math.random() * Tiers.length)];
    document.getElementById("viewTier").innerHTML = randomTiers;
  }

  return (
    <div className='random3'>
      <button id="boutonObjetsAleatoires" className="btn btn-aleatoire" onClick={obtenirObjetsAleatoires}>
        Invoquer 3 objets
      </button>
      {objetsAleatoires.length > 0 && (
        <div className='containerObject'>
          {objetsAleatoires.map((objet, index) => (
            <div key={index}>
              <p>{objet.nom}</p>
              <img src={objet.image} alt={objet.nom} className='imgObject' />
            </div>
          ))}
        </div>
      )}
      <div>
        <button id="boutonObjetAleatoire" className="btn btn-aleatoire" onClick={getRandomTiers}>
          Tier des objects
        </button>
        <p id="viewTier"></p>
      </div>
    </div>
  );
}