import React, { useState } from "react";
import { FaTimes, FaExclamationTriangle } from 'react-icons/fa';

import './Alert.scss'

export default function Warning() {

    const [showAlert, setShowAlert] = useState(true)


    const closeAlert = () => {
        setShowAlert(false)
    }
    
    return (
        <section>
            <div className="container mt-5">
                <div className="row">
                <div className="col-sm-12">
                {showAlert && (
                      <div className="alert fade alert-simple alert-warning alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show" role="alert" data-brk-library="component__alert">
                              <FaExclamationTriangle />
                          <i className="start-icon fa fa-exclamation-triangle faa-flash animated"></i>
                          <strong className="font__weight-semibold">Attention!</strong> Cette page contient tous les pouvoirs cachés des entités. 
                              <FaTimes onClick={closeAlert} className="sr-only close" data-dismiss="alert"  />
                      </div>
                    )}
                </div>
                </div>
            </div>
        </section>
    )
}