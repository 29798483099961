import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import  Burger  from './burger'
import './assets/scss/Navbar.scss'
import './assets/scss/burger.scss'

export default function Navbar() {

  const [isBurger, setIsBurger] = useState(false)

    return(

    <div className='navbar'>
    <button className='btn btn-primary' onClick={() => setIsBurger(!isBurger)}>
    <div className="menu-icon"></div>
    </button>
    <Burger isOpen={isBurger} onClose={() => setIsBurger(!isBurger)}>
      <div className="container-fluid">
                <div className="link">
                  <ul className='navbar-ul'>
                  <Link className='logo' to="/" onClick={() => setIsBurger(!isBurger)}>Accueil</Link>
                  <Link to="/object" onClick={() => setIsBurger(!isBurger)}>Ojects</Link>
                  <Link to="/items" onClick={() => setIsBurger(!isBurger)}>Items</Link>
                  <Link to="/actu" onClick={() => setIsBurger(!isBurger)}>Actu</Link>
                  <Link to="/random" onClick={() => setIsBurger(!isBurger)}>Random</Link>
                  </ul>
                </div>
              </div>
    </Burger>
    </div>
    )
}