import React, { useEffect, useState } from 'react';

import './../assets/scss/Actu.scss';

export default function Actu() {
    const [actu, setActu] = useState([]);

    useEffect(() => {
        const apiUrl = 'https://phasmophobiaastuce.fr/API_phasmophobiaastuce/actu';
        fetch(apiUrl)
            .then(response => {
                return response.json();
            })
            
            .then(data => {
                console.log("DATA ",data);
                if (data.appnews && data.appnews.newsitems) {
                    // Ajouter une classe CSS pour masquer les balises [img]
                    const newsItemsWithHiddenImages = data.appnews.newsitems.map(item => {
                        if (item.contents) {
                            // Utilisez une expression régulière pour ajouter la classe CSS aux balises [img]
                            // item.contents = item.contents.replace(/\[img\]/g, '<span class="hidden-img">').replace(/\[\/img\]/g, '</span>');
                            item.contents = item.contents.replace(/\{STEAM_CLAN_IMAGE\}(.+?)\[\/img\]/g, '<img src="https://clan.cloudflare.steamstatic.com/images//$1">');
                            item.contents = item.contents.replace(/\[list\]/g, '<ul class="ul-content-actu">').replace(/\[\/list\]/g, '</ul>');
                            item.contents = item.contents.replace(/\[\*\]/g, '<li>');
                            item.contents = item.contents.replace(/\[img\]/g, '');
                            item.contents = item.contents.replace(/\[i\]/g, '<em>').replace(/\[\/i\]/g, '</em>');
                            item.contents = item.contents.replace(/\[h3\]/g, '<h3>').replace(/\[\/h3\]/g, '</h3>');
                            item.contents = item.contents.replace(/\[h2\]/g, '<h2 style="padding: 1%;">').replace(/\[\/h2\]/g, '</h2>');
                            item.contents = item.contents.replace(/\[b\]/g, '<b style="padding: 1%;">').replace(/\[\/b\]/g, '</b>');
                            item.contents = item.contents.replace(/(https:\/\/discord[^\s]+)/g, '<a class="a_discord" href="$1" target="_blank">$1</a>')
                            item.contents = item.contents.replace(/<img([^>]*)>/g, (match, capture) => {return `<img class="img-content-actu" ${capture}>`;})
                        }
                        return item;
                    });
                    console.log(newsItemsWithHiddenImages);
                    setActu(newsItemsWithHiddenImages);         
                }else {
                    console.warn('Données API ou éléments d\'actualité introuvables');
                    
                }
            })
            .catch(error => console.error(error));
        }, []);
        console.log("ACTU ",actu);

    return (
        <div className='actu'>
            {actu.map((actus, index) => {
                // Exemple de lien Steam Community pour chaque actualité
                const steamImageLinks = actus.contents.match(/\[img\]{STEAM_CLAN_IMAGE}(.+?)\[\/img\]/g); // Trouve tous les liens Steam Community dans le contenu
                const imageElements = []; // Tableau pour stocker les éléments img
                
                if (steamImageLinks) {
                    steamImageLinks.forEach((link, imgIndex) => {
                        const baseUrl = "https://steamcdn-a.akamaihd.net/steamcommunity/public/images/clans/";
                        const regex = /\[img\]{STEAM_CLAN_IMAGE}(.+?)\[\/img\]/;
                        const matches = link.match(regex);

                        if (matches && matches.length === 2) {
                            const imagePath = matches[1];
                            const imageUrl = baseUrl + imagePath;

                            // Ajouter l'élément img au tableau
                            imageElements.push(
                                <img
                                    key={imgIndex}
                                    src={imageUrl}
                                    alt={`imgSourceSteam ${imgIndex}`}
                                    class='img'
                                />
                            );
                        } else {
                            console.error("Lien d'image Steam Community non valide");
                        }
                    });
                }
                // console.log(actu);

                return (
                    <div className='actu-content' key={index}>
                        <h2>{actus.title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: actus.contents }} className='p-content-actu'></p>
                        <div>
                            {imageElements.map((imgElement, imgIndex) => (
                                // Afficher chaque image individuellement
                                <div key={imgIndex}>{imgElement}</div>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}