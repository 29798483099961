import react, { useContext, useEffect, useState } from 'react';
// import SearchBar from '../SearchBar'
import { ImageContext } from '../ImageProvider';
import './cardsItem.scss';

export default function CardsItem() {
    const images = useContext(ImageContext);
    const [item, setItem] = useState([])

    const updateResults = (data, queryval) => {
        // Filtrer les Items en fonction du terme de recherche
        const filteredItems = data.filter((item) => {
          const searchTerm = queryval.toLowerCase();
          return item.name.toLowerCase().includes(searchTerm);
        });
      
        // Mettre à jour l'état avec les items filtrés
        setItem(filteredItems);
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://phasmophobiaastuce.fr/API_phasmophobiaastuce/items', {
                    timeout: 10000,
                });

                if (!response.ok) {
                    throw new Error('Réponse du serveur non OK');
                }
                const data = await response.json();
                setItem(data)

            } catch (error) {
                console.error('Erreur de fetch', error);
            }
        };

        fetchData();
    }, [])

    return (
        <>
        {/* <SearchBar updateResults={updateResults} /> */}
        <div className='Container-items'>
            {Array.isArray(item) ? (
                item.map(items => {
                    return (
                        <div key={items.id} className='item-content'>
                            <div className='item-tier'>
                                <h3>{items.name} {items.tier}</h3>
                                {/* <img src={images.emf3} alt='e' className='imgItem' /> */}
                                {items.name === "Livre" && items.tier === 1 ? <img src={images.book1} alt='book' className='imgItem' /> :
                                items.name === "Livre" && items.tier === 2 ? <img src={images.book2} alt='book2' className='imgItem' /> :
                                items.name === "Livre" && items.tier === 3 ? <img src={images.book3} alt='book3' className='imgItem' /> :
                                
                                items.name === "Caméra" && items.tier === 1 ? <img src={images.camera1} alt='camera' className='imgItem' /> :
                                items.name === "Caméra" && items.tier === 2 ? <img src={images.camera2} alt='camera2' className='imgItem' /> :
                                items.name === "Caméra" && items.tier === 3 ? <img src={images.camera3} alt='camera3' className='imgItem' /> :
                                
                                items.name === "Bougie" && items.tier === 1 ? <img src={images.candle1} alt='candle' className='imgItem' /> :
                                items.name === "Bougie" && items.tier === 2 ? <img src={images.candle2} alt='candle2' className='imgItem' /> :
                                items.name === "Bougie" && items.tier === 3 ? <img src={images.candle3} alt='candle3' className='imgItem' /> :
                                
                                items.name === "Crucifix" && items.tier === 1 ? <img src={images.crucifix1} alt='crucifix' className='imgItem' /> :
                                items.name === "Crucifix" && items.tier === 2 ? <img src={images.crucifix2} alt='crucifix2' className='imgItem' /> :
                                items.name === "Crucifix" && items.tier === 3 ? <img src={images.crucifix3} alt='crucifix3' className='imgItem' /> :
                                
                                items.name === "DOTS" && items.tier === 1 ? <img src={images.dots1} alt='dots' className='imgItem' /> :
                                items.name === "DOTS" && items.tier === 2 ? <img src={images.dots2} alt='dots2' className='imgItem' /> :
                                items.name === "DOTS" && items.tier === 3 ? <img src={images.dots3} alt='dots3' className='imgItem' /> :

                                items.name === "EMF" && items.tier === 1 ? <img src={images.emf1} alt='emf' className='imgItem' /> :
                                items.name === "EMF" && items.tier === 2 ? <img src={images.emf2} alt='emf2' className='imgItem' /> :
                                items.name === "EMF" && items.tier === 3 ? <img src={images.emf3} alt='emf3' className='imgItem' /> :
                                
                                items.name === "Encens" && items.tier === 1 ? <img src={images.encens1} alt='encens' className='imgItem' /> :
                                items.name === "Encens" && items.tier === 2 ? <img src={images.encens2} alt='encens2' className='imgItem' /> :
                                items.name === "Encens" && items.tier === 3 ? <img src={images.encens3} alt='encens3' className='imgItem' /> :
                                
                                items.name === "Allumeur" && items.tier === 1 ? <img src={images.flint1} alt='flint' className='imgItem' /> :
                                items.name === "Allumeur" && items.tier === 2 ? <img src={images.flint2} alt='flint2' className='imgItem' /> :
                                items.name === "Allumeur" && items.tier === 3 ? <img src={images.flint3} alt='flint3' className='imgItem' /> :
                                
                                items.name === "Équipement de tête" && items.tier === 1 ? <img src={images.head1} alt='head' className='imgItem' /> :
                                items.name === "Équipement de tête" && items.tier === 2 ? <img src={images.head2} alt='head2' className='imgItem' /> :
                                items.name === "Équipement de tête" && items.tier === 3 ? <img src={images.head3} alt='head3' className='imgItem' /> :
                                
                                items.name === "Mouvement" && items.tier === 1 ? <img src={images.movement1} alt='movement' className='imgItem' /> :
                                items.name === "Mouvement" && items.tier === 2 ? <img src={images.movement2} alt='movement2' className='imgItem' /> :
                                items.name === "Mouvement" && items.tier === 3 ? <img src={images.movement3} alt='movement3' className='imgItem' /> :
                                
                                items.name === "Parabole" && items.tier === 1 ? <img src={images.parabol1} alt='parabol' className='imgItem' /> :
                                items.name === "Parabole" && items.tier === 2 ? <img src={images.parabol2} alt='parabol2' className='imgItem' /> :
                                items.name === "Parabole" && items.tier === 3 ? <img src={images.parabol3} alt='parabol3' className='imgItem' /> :
                                
                                items.name === "Photo" && items.tier === 1 ? <img src={images.photo1} alt='photo' className='imgItem' /> :
                                items.name === "Photo" && items.tier === 2 ? <img src={images.photo2} alt='photo2' className='imgItem' /> :
                                items.name === "Photo" && items.tier === 3 ? <img src={images.photo3} alt='photo3' className='imgItem' /> :
                                
                                items.name === "Pilule" && items.tier === 1 ? <img src={images.pillule1} alt='pilule' className='imgItem' /> :
                                items.name === "Pilule" && items.tier === 2 ? <img src={images.pillule2} alt='pilule2' className='imgItem' /> :
                                items.name === "Pilule" && items.tier === 3 ? <img src={images.pillule3} alt='pilule3' className='imgItem' /> :
                                
                                items.name === "Sel" && items.tier === 1 ? <img src={images.sel1} alt='sel' className='imgItem' /> :
                                items.name === "Sel" && items.tier === 2 ? <img src={images.sel2} alt='sel2' className='imgItem' /> :
                                items.name === "Sel" && items.tier === 3 ? <img src={images.sel3} alt='sel3' className='imgItem' /> :
                                
                                items.name === "Sonore" && items.tier === 1 ? <img src={images.sonor1} alt='sonor2' className='imgItem' /> :
                                items.name === "Sonore" && items.tier === 2 ? <img src={images.sonor2} alt='sonor2' className='imgItem' /> :
                                items.name === "Sonore" && items.tier === 3 ? <img src={images.sonor3} alt='sonor2' className='imgItem' /> :
                                
                                items.name === "Spirit box" && items.tier === 1 ? <img src={images.spiritbox1} alt='spiritbox' className='imgItem' /> :
                                items.name === "Spirit box" && items.tier === 2 ? <img src={images.spiritbox2} alt='spiritbox2' className='imgItem' /> :
                                items.name === "Spirit box" && items.tier === 3 ? <img src={images.spiritbox3} alt='spiritbox3' className='imgItem' /> :
                                
                                items.name === "Thermomètre" && items.tier === 1 ? <img src={images.thermo1} alt='thermo' className='imgItem' /> :
                                items.name === "Thermomètre" && items.tier === 2 ? <img src={images.thermo2} alt='thermo2' className='imgItem' /> :
                                items.name === "Thermomètre" && items.tier === 3 ? <img src={images.thermo3} alt='thermo3' className='imgItem' /> :
                                
                                items.name === "Torche" && items.tier === 1 ? <img src={images.torche1} alt='torche' className='imgItem' /> :
                                items.name === "Torche" && items.tier === 2 ? <img src={images.torche2} alt='torche2' className='imgItem' /> :
                                items.name === "Torche" && items.tier === 3 ? <img src={images.torche3} alt='torche3' className='imgItem' /> :

                                items.name === "Trépied" && items.tier === 1 ? <img src={images.trepied1} alt='trepied' className='imgItem' /> :
                                items.name === "Trépied" && items.tier === 2 ? <img src={images.trepied2} alt='trepied2' className='imgItem' /> :
                                items.name === "Trépied" && items.tier === 3 ? <img src={images.trepied3} alt='trepied3' className='imgItem' /> :
                                
                                items.name === "UV" && items.tier === 1 ? <img src={images.uv1} alt='uv' className='imgItem' /> :
                                items.name === "UV" && items.tier === 2 ? <img src={images.uv2} alt='uv2' className='imgItem' /> :
                                items.name === "UV" && items.tier === 3 ? <img src={images.uv3} alt='uv3' className='imgItem' /> :
                                
                                <p>Error</p>}
                                <p>{items.description.split('.').map((sentence, i) => (
                                                <span key={i}>
                                                  {i > 0 && <br />} {sentence.trim()}.{" "}
                                                </span>
                                              ))}</p>
                            </div>
                        </div>
                    )
                })
            ) : (<p>Une erreur est survenu</p>)}
            
        </div></>
    );
}