// import logo from './logo.svg';
import './components/assets/scss/App.scss';
import {Routes, Route} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/home/Home';
import ErrorBoundary from './components/ErrorBoudary';
// import Actu from './components/actu/Actu';
import Actu from './components/actu/actum';
import Iteams from './components/iteams/Items';
import Object from './components/object/Object';
import Picture from './components/picture/Picture';
import Random from './components/random/Random';
import { ImageProvider } from './components/ImageProvider';
import { useMediaQuery } from '@uidotdev/usehooks';
import MobileNavbar from './components/MobileNavbar';


function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className="App">
      <>{isMobile ? <MobileNavbar /> : <Navbar />}</>
      {/* <Navbar /> */}
      <Routes>
        <Route path='/' element={<ErrorBoundary> 
                                    <ImageProvider> 
										<Home /> 
									</ImageProvider> 
								</ErrorBoundary>}/>
        <Route path='/actu' element={<ErrorBoundary> 
										<Actu />
								</ErrorBoundary>}/>
        <Route path='/items' element={<ErrorBoundary> 
                                    <ImageProvider> 
										<Iteams />
									</ImageProvider> 
								</ErrorBoundary> }/>
        <Route path='/object' element={<ErrorBoundary> 
										<Object />
								</ErrorBoundary>}/>
        <Route path='/picture' element={<ErrorBoundary> 
										<Picture />
								</ErrorBoundary>}/>
        <Route path='/random' element={<ErrorBoundary> 
                                    <ImageProvider> 
										<Random />
									</ImageProvider> 
								</ErrorBoundary>}/>
      </Routes>
    </div>
  );
}

export default App;
