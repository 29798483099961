import React from "react";

export default function burger({isOpen, onClose, children}) {

    return (
        <div className={`burger ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="burger-content" onClick={(e) => e.stopPropagation()}>
                {children}
                <button className="close-btn" onClick={onClose}>
                <div className="cross-icon"></div>
                </button>
            </div>
        </div>
    );
}