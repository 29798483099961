import React from 'react'
import './../assets/scss/Overlay.scss'

import close from './../assets/img/close.png'

export default function Overlay({ isOpen, onClose, children}) {
  
  return (
    <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        {children}
        <button className="close-btn btn-overlay" onClick={onClose}>
        <img src={close} alt='close' />
        </button>
      </div>
    </div>
  );
}

// return (
//   <div>
//     {
//       isOpen ? (
//       <div className='overlay'>
//         <div className="overlay__background" onClick={onClose} />
//         <div className="overlay__container">
//           <div className="overlay__controls">
//             <button className='overlay__close' type='button' onClick={onClose}></button>
//           </div>
//           {children}
//         </div>
//       </div>
//       ) : null
//     }    
//   </div>
// )