import React from 'react'
import CardsItem from './cardsItem'

export default function Items() {
  return (
    <div>
      <CardsItem />
    </div>
  )
}
