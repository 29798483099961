import React, { useState } from "react";
import { FaTimes, FaExclamationTriangle } from 'react-icons/fa';


import './Alert.scss'

export default function Error() {

    const [showAlert, setShowAlert] = useState(true)


    const closeAlert = () => {
        setShowAlert(false)
    }

    return (
        <section>
            <div className="container mt-5">
                <div className="row">
                <div className="col-sm-12">
                {showAlert && (
                    <div className="alert fade alert-simple alert-danger alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show" role="alert" data-brk-library="component__alert">
                              <FaExclamationTriangle />
                    <strong className="font__weight-semibold">Attention!</strong> Ce site est en cours de développement, plusieurs fonctionnalités ne sont pas disponibles pour le moment.
                              <FaTimes onClick={closeAlert} className="sr-only close" data-dismiss="alert"  />
                    </div>
                )}
                </div>
                </div>
            </div>
        </section>
    )
}