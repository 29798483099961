import React from 'react'
import { useState, useEffect } from 'react'
import Cards from '../home/Cards'
import NavMap from '../NavMap'

export default function Object() {
  const [obj, setObj] = useState([])


  useEffect(() => {
      fetch('https://phasmophobiaastuce.fr/API_phasmophobiaastuce/cursed')
          .then(response => {
              // console.log(response);
              return response.json();
          })
          .then(data => {
              // console.log(data);
              setObj(data)
          })
  }, [])

  return (
      <>
          <NavMap />

          <div className="container d-flex">
              {
                  obj.map(o => {
                      return (                     
                          <div className="slide-container" key={o.id}>
                                  <div className="wrapper">
                                      <div className="clash-card barbarian">
                                          <div className="clash-card__unit-name">{o.name}</div>
                                          <hr />
                                          <div className="clash-card__unit-description">{" "}
                                              {o.caracteristique.split('.' || '!').map((sentence, i) => (
                                                <span key={i}>
                                                  {i > 0 && <br />} {sentence.trim()}.{" "}
                                                </span>
                                              ))}
                                          </div>
                                          <div className="clash-card__unit-stats clash-card__unit-stats--barbarian clearfix">
                                              

                                              
                                          </div>
                                      </div>
                                  </div>
                              </div>
                      )
                  })
              } </div>
              </>
              )
          }
