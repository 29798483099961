import React from 'react'
import { useState } from 'react'
import Overlay from './overlay/Overlay'
import './assets/scss/NavMap.scss'
import './assets/scss/Overlay.scss'

import tanglewood from './assets/img/map/tanglewood.png'
import tanglewoodCave from './assets/img/map/tanglewood_cave.png'
import edgefieldFirst from './assets/img/map/edgefield_first.png'
import edgefield from './assets/img/map/edgefield.png'
import edgefieldCave from './assets/img/map/edgefield_cave.png'
import ridgeviewFirst from './assets/img/map/ridgeview_first.png'
import ridgeview from './assets/img/map/ridgeview.png'
import ridgeviewCave from './assets/img/map/ridgeview_cave.png'
import graftonFirst from './assets/img/map/grafton_first.png'
import grafton from './assets/img/map/grafton.png'
import willow from './assets/img/map/willow.png'
import willowCave from './assets/img/map/willow_cave.png'
import schoolFirst from './assets/img/map/school_first.png'
import school from './assets/img/map/school.png'
import bleasdaleFirst from './assets/img/map/bleasdale_first.png'
import bleasdale from './assets/img/map/bleasdale.png'
// import sunnyFirst from './assets/img/map/sunny_first.png'
// import sunny from './assets/img/map/sunny.png'
// import sunnyr from './assets/img/map/sunny.png'
// import sunnyrr from './assets/img/map/sunny.png'
import prisonFirst from './assets/img/map/prison_first.png'
import prison from './assets/img/map/prison.png'
// import campsite from './assets/img/map/campsite.png'
// import camp from './assets/img/map/camp.png'


export default function NavMap() {

  const [isOverlayOpenTanglewood, setIsOverlayOpenTanglewood] = useState(false)
  const [isOverlayOpenEdgefield, setIsOverlayOpenEdgefield] = useState(false)
  const [isOverlayOpenRidgeview, setIsOverlayOpenRidgeview] = useState(false)
  const [isOverlayOpenGrafton, setIsOverlayOpenGrafton] = useState(false)
  const [isOverlayOpenWillow, setIsOverlayOpenWillow] = useState(false)
  const [isOverlayOpenSchool, setIsOverlayOpenSchool] = useState(false)
  const [isOverlayOpenBleasdale, setIsOverlayOpenBleasdale] = useState(false)
  const [isOverlayOpenSunny, setIsOverlayOpenSunny] = useState(false)
  const [isOverlayOpenSunnyR, setIsOverlayOpenSunnyR] = useState(false)
  const [isOverlayOpenPrison, setIsOverlayOpenPrison] = useState(false)
  const [isOverlayOpenCampsite, setIsOverlayOpenCampsite] = useState(false)
  const [isOverlayOpenCamp, setIsOverlayOpenCamp] = useState(false)

  

  return (
    <div className='navmap'>
    <button className='btn btn-primary' onClick={() => setIsOverlayOpenTanglewood(!isOverlayOpenTanglewood)}>Tanglewood</button>
    <Overlay isOpen={isOverlayOpenTanglewood} onClose={() => setIsOverlayOpenTanglewood(!isOverlayOpenTanglewood)}>
      <img src={tanglewood} alt="tanglewood" />
      <img src={tanglewoodCave} alt="tanglewood_cave" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenEdgefield(!isOverlayOpenEdgefield)}>Edgefield</button>
    <Overlay isOpen={isOverlayOpenEdgefield} onClose={() => setIsOverlayOpenEdgefield(!isOverlayOpenEdgefield)}>
      <img src={edgefieldFirst} alt="edgefield_first" />
      <img src={edgefield} alt="edgefield" />
      <img src={edgefieldCave} alt="edgefield_cave" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenRidgeview(!isOverlayOpenRidgeview)}>Ridgeview</button>
    <Overlay isOpen={isOverlayOpenRidgeview} onClose={() => setIsOverlayOpenRidgeview(!isOverlayOpenRidgeview)}>
    <img src={ridgeviewFirst} alt="ridgeview_first" />
    <img src={ridgeview} alt="ridgeview" />
    <img src={ridgeviewCave} alt="ridgeview_cave" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenGrafton(!isOverlayOpenGrafton)}>Grafton</button>
    <Overlay isOpen={isOverlayOpenGrafton} onClose={() => setIsOverlayOpenGrafton(!isOverlayOpenGrafton)}>
    <img src={graftonFirst} alt="grafton_first" />
    <img src={grafton} alt="grafton" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenWillow(!isOverlayOpenWillow)}>Willow</button>
    <Overlay isOpen={isOverlayOpenWillow} onClose={() => setIsOverlayOpenWillow(!isOverlayOpenWillow)}>
      <img src={willow} alt="willow" />
      <img src={willowCave} alt="willow_cave" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenSchool(!isOverlayOpenSchool)}>School</button>
    <Overlay isOpen={isOverlayOpenSchool} onClose={() => setIsOverlayOpenSchool(!isOverlayOpenSchool)}>
    <img src={schoolFirst} alt="school_first" />
    <img src={school} alt="school" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenBleasdale(!isOverlayOpenBleasdale)}>Bleasdale</button>
    <Overlay isOpen={isOverlayOpenBleasdale} onClose={() => setIsOverlayOpenBleasdale(!isOverlayOpenBleasdale)}>
    <img src={bleasdaleFirst} alt="bleasdale_first" />
    <img src={bleasdale} alt="bleasdale" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenSunny(!isOverlayOpenSunny)}>Sunny</button>
    <Overlay isOpen={isOverlayOpenSunny} onClose={() => setIsOverlayOpenSunny(!isOverlayOpenSunny)}>
    {/* <img src={sunnyFirst} alt="sunny_first" />
    <img src={sunny} alt="sunny" /> */}
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenSunnyR(!isOverlayOpenSunnyR)}>SunnyR</button>
    <Overlay isOpen={isOverlayOpenSunnyR} onClose={() => setIsOverlayOpenSunnyR(!isOverlayOpenSunnyR)}>
    {/* <img src={sunnyr} alt="sunnyr" />
    <img src={sunnyrr} alt="sunnyr" /> */}
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenPrison(!isOverlayOpenPrison)}>Prison</button>
    <Overlay isOpen={isOverlayOpenPrison} onClose={() => setIsOverlayOpenPrison(!isOverlayOpenPrison)}>
    <img src={prisonFirst} alt="prison_first" />
    <img src={prison} alt="prison" />
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenCampsite(!isOverlayOpenCampsite)}>Campsite</button>
    <Overlay isOpen={isOverlayOpenCampsite} onClose={() => setIsOverlayOpenCampsite(!isOverlayOpenCampsite)}>
    {/* <img src={campsite} alt="campsite" /> */}
    </Overlay>

    <button className='btn btn-primary' onClick={() => setIsOverlayOpenCamp(!isOverlayOpenCamp)}>Camp</button>
    <Overlay isOpen={isOverlayOpenCamp} onClose={() => setIsOverlayOpenCamp(!isOverlayOpenCamp)}>
    {/* <img src={camp} alt="camp" /> */}
    </Overlay>
    </div>
  )
}