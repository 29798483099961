import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { ImageContext } from '../ImageProvider';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaAndroid } from 'react-icons/fa'
import NavMap from '../NavMap'
import SearchBar from '../SearchBar'
import Warning from '../alert/Warning'
import Error from '../alert/Error'
import './../assets/scss/Home.scss'



export default function Home() {

  const images = useContext(ImageContext);

    const [ghost, setGhost] = useState([])

    const updateResults = (data, queryval) => {
      // Filtrer les fantômes en fonction du terme de recherche
      const filteredGhosts = data.filter((ghost) => {
        const searchTerm = queryval.toLowerCase();
        return ghost.name.toLowerCase().includes(searchTerm);
      });
    
      // Mettre à jour l'état avec les fantômes filtrés
      setGhost(filteredGhosts);
    };


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch('https://phasmophobiaastuce.fr/API_phasmophobiaastuce/ghost', {
              timeout: 10000, // spécifie un timeout de 10 secondes (en millisecondes)
            });
      
            if (!response.ok) {
              throw new Error('Réponse du serveur non OK');
            }
      
            const data = await response.json();
            setGhost(data);
          } catch (error) {
            console.error('Erreur de fetch', error);
          }
        };
      
        fetchData();
      }, []);

    return (
        <>
            <SearchBar updateResults={updateResults} />
            <NavMap />
            <Error />
            <Warning />
            

            <div className="container d-flex">
                { Array.isArray(ghost) ? (
                  
                    ghost.map(ghosts => {
                        return (                     
                            <div className="slide-container" key={ghosts.id}>
                                    <div className="wrapper">
                                        <div className="clash-card barbarian">
                                            <div className="clash-card__unit-name">{ghosts.name}</div>
                                            <hr />
                                            <div className="clash-card__unit-description">{" "}
                                            {ghosts.power.split('.').map((sentence, i) => (
                                                <span key={i}>
                                                  {i > 0 && <br />} {sentence.trim()}.{" "}
                                                </span>
                                              ))}                                                
                                            </div>
                                            <div className="clash-card__unit-stats clash-card__unit-stats--barbarian clearfix">
                                            <hr />
                                                <div className="one-third">
                                                {ghosts.evidence === "EMF" ? <img src={images.emf2} alt='emf2' className='imgEvidence' /> :
                                                    ghosts.evidence === "Spirit Box" ? <img src={images.spiritbox2} alt='spiritbox2' className='imgEvidence' /> :
                                                    ghosts.evidence === "Écriture fantomatique" ? <img src={images.book2} alt='book2' className='imgEvidence' /> :
                                                    ghosts.evidence === "D.O.T.S" ? <img src={images.dots2} alt='dots2' className='imgEvidence' /> :
                                                    ghosts.evidence === "Empreintes digitales" ? <img src={images.uv2} alt='uv2' className='imgEvidence' /> :
                                                    ghosts.evidence === "Températures glaciales" ? <img src={images.thermo2} alt='thermo2' className='imgEvidence' /> :
                                                    ghosts.evidence === "Orbe fantomatique" ? <img src={images.camera2} alt='camera2' className='imgEvidence' /> : <p>Error</p>}
                                                </div>

                                                <div className="one-third">
                                                    {ghosts.evidence2 === "EMF" ? <img src={images.emf2} alt='emf2' className='imgEvidence' /> :
                                                    ghosts.evidence2 === "Spirit Box" ? <img src={images.spiritbox2} alt='spiritbox2' className='imgEvidence' /> :
                                                    ghosts.evidence2 === "Écriture fantomatique" ? <img src={images.book2} alt='book2' className='imgEvidence' /> :
                                                    ghosts.evidence2 === "D.O.T.S" ? <img src={images.dots2} alt='dots2' className='imgEvidence' /> :
                                                    ghosts.evidence2 === "Empreintes digitales" ? <img src={images.uv2} alt='uv2' className='imgEvidence' /> :
                                                    ghosts.evidence2 === "Températures glaciales" ? <img src={images.thermo2} alt='thermo2' className='imgEvidence' /> :
                                                    ghosts.evidence2 === "Orbe fantomatique" ? <img src={images.camera2} alt='camera2' className='imgEvidence' /> : <p>Error</p>}
                                                </div>

                                                <div className="one-third">
                                                    {ghosts.evidence3 === "EMF" ? <img src={images.emf2} alt='emf2' className='imgEvidence' /> :
                                                    ghosts.evidence3 === "Spirit Box" ? <img src={images.spiritbox2} alt='spiritbox2' className='imgEvidence' /> :
                                                    ghosts.evidence3 === "Écriture fantomatique" ? <img src={images.book2} alt='book2' className='imgEvidence' /> :
                                                    ghosts.evidence3 === "D.O.T.S" ? <img src={images.dots2} alt='dots2' className='imgEvidence' /> :
                                                    ghosts.evidence3 === "Empreintes digitales" ? <img src={images.uv2} alt='uv2' className='imgEvidence' /> :
                                                    ghosts.evidence3 === "Températures glaciales" ? <img src={images.thermo2} alt='thermo2' className='imgEvidence' /> :
                                                    ghosts.evidence3 === "Orbe fantomatique" ? <img src={images.camera2} alt='camera2' className='imgEvidence' /> : <p>Error</p>}
                                                </div>

                                                <div className="one-third no-border">
                                                    <div className="stat">{ghosts.mental_health} %</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )
                    })
                    ) : (<p>Une erreur est survenu</p>)
                } </div>
                </>
                )
            }