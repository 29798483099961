import React, { createContext, useState, useEffect } from 'react';

const ImageContext = createContext();

const ImageProvider = ({ children }) => {
  const [images, setImages] = useState({});

  useEffect(() => {
    // Charger les images de manière asynchrone ici
    setImages({
      book1: require('./assets/img/items/book/Livre1.webp'),
      book2: require('./assets/img/items/book/Livre2.webp'),
      book3: require('./assets/img/items/book/Livre3.webp'),
      
      camera1: require('./assets/img/items/camera/Cam3Fo1.webp'),
      camera2: require('./assets/img/items/camera/CameraVideo2.webp'),
      camera3: require('./assets/img/items/camera/Cam3Fo3.webp'),

      candle1: require('./assets/img/items/candle/Lumièreàfeu1.webp'),
      candle2: require('./assets/img/items/candle/Lumi3Ffeu2.webp'),
      candle3: require('./assets/img/items/candle/Lumi3Ffeu3.webp'),

      crucifix1: require('./assets/img/items/crucifix/Crucifix1.webp'),
      crucifix2: require('./assets/img/items/crucifix/Crucifix2.webp'),
      crucifix3: require('./assets/img/items/crucifix/Crucifix3.webp'),

      dots1: require('./assets/img/items/dots/DOTS1.webp'),
      dots2: require('./assets/img/items/dots/DOTS2.webp'),
      dots3: require('./assets/img/items/dots/DOTS3.webp'),

      emf1: require('./assets/img/items/emf/LecteurEMF1.webp'),
      emf2: require('./assets/img/items/emf/LecteurEMF2.webp'),
      emf3: require('./assets/img/items/emf/LecteurEMF3.webp'),

      encens1: require('./assets/img/items/encens/Encens1.webp'),
      encens2: require('./assets/img/items/encens/Encens2.webp'),
      encens3: require('./assets/img/items/encens/Encens3.webp'),

      flint1: require('./assets/img/items/flint/Allumeur1.webp'),
      flint2: require('./assets/img/items/flint/Allumeur2.webp'),
      flint3: require('./assets/img/items/flint/Allumeur3.webp'),

      head1: require('./assets/img/items/head/ÉquipementDeTête1.webp'),
      head2: require('./assets/img/items/head/3Fte2.webp'),
      head3: require('./assets/img/items/head/3Fte3.webp'),

      movement1: require('./assets/img/items/movement/DétecteurDeMouvement1.webp'),
      movement2: require('./assets/img/items/movement/Mouvement2.webp'),
      movement3: require('./assets/img/items/movement/Mouvement3.webp'),

      parabol1: require('./assets/img/items/parabol/MicrophoneParabolique1.webp'),
      parabol2: require('./assets/img/items/parabol/MicrophoneParabolique2.webp'),
      parabol3: require('./assets/img/items/parabol/MicrophoneParabolique3.webp'),

      photo1: require('./assets/img/items/photo/AppareilPhoto1.webp'),
      photo2: require('./assets/img/items/photo/AppareilPhoto2.webp'),
      photo3: require('./assets/img/items/photo/AppareilPhoto3.webp'),

      pillule1: require('./assets/img/items/pillule/M3FMentale1.webp'),
      pillule2: require('./assets/img/items/pillule/MédicamentDeSantéMentale2.webp'),
      pillule3: require('./assets/img/items/pillule/M3FMentale3.webp'),

      sel1: require('./assets/img/items/sel/Sel1.webp'),
      sel2: require('./assets/img/items/sel/Sel2.webp'),
      sel3: require('./assets/img/items/sel/Sel3.webp'),

      sonor1: require('./assets/img/items/sonor/CapteurSonore1.webp'),
      sonor2: require('./assets/img/items/sonor/CapteurSonore2.webp'),
      sonor3: require('./assets/img/items/sonor/CapteurSonore3.webp'),

      spiritbox1: require('./assets/img/items/spiritbox/SpiritBox1.webp'),
      spiritbox2: require('./assets/img/items/spiritbox/SpiritBox2.webp'),
      spiritbox3: require('./assets/img/items/spiritbox/SpiritBox3.webp'),

      thermo1: require('./assets/img/items/thermo/Thermometre1.webp'),
      thermo2: require('./assets/img/items/thermo/Thermometre2.webp'),
      thermo3: require('./assets/img/items/thermo/Thermometre3.webp'),

      torche1: require('./assets/img/items/torche/LampeDePoche1.webp'),
      torche2: require('./assets/img/items/torche/LampeDePoche2.webp'),
      torche3: require('./assets/img/items/torche/LampeDePoche3.webp'),

      trepied1: require('./assets/img/items/trepied/Trépied1.webp'),
      trepied2: require('./assets/img/items/trepied/Trepied2.webp'),
      trepied3: require('./assets/img/items/trepied/Trepied3.webp'),

      uv1: require('./assets/img/items/uv/LampeUV1.webp'),
      uv2: require('./assets/img/items/uv/LampeUV2.webp'),
      uv3: require('./assets/img/items/uv/LampeUV3.webp'),
    });
  }, []);

  return (
    <ImageContext.Provider value={images}>
      {children}
    </ImageContext.Provider>
  );
};

export { ImageContext, ImageProvider };